






























import { Component, Vue } from 'vue-property-decorator'
import Content from '@/components/Content.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { firestore } from 'firebase/app'
import { User } from '@/models/User'
import { UserConverter } from '@/models/converters/UserConverter'
import { TableHeader } from '@/models/TableHeader'

@Component({
  components: {
    Content,
    Button,
    Table
  }
})
export default class AdminOrder extends Vue {
  headers: TableHeader[] = [
    { key: 'user', title: 'Benutzer' },
    { key: 'credits', title: 'Guthaben' }
  ]
  query: firestore.Query<User> = firestore()
    .collection('users')
    .withConverter(new UserConverter())
    .orderBy('name', 'asc')
}
